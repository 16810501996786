import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';

// models
import {Clients,ClientShops,Tariffs} from './../../Models';

// helpers
import {moneyFormat,dateGet,dateRound} from '../../Globals/Utils';

// globals
import {comonStatus,comonStatusName} from '../../Globals/Constants';

// styles
import './styles.css';

const ShopScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const [status, setStatus] = useState(comonStatus.ACTIVE);
	const [clientName, setClientName] = useState('');
	const [tariffName, setTariffName] = useState('');
	const [price, setPrice] = useState(0);
	const [priceDiscount, setPriceDiscount] = useState(0);
	const [priceFull, setPriceFull] = useState(0);
	const [period, setPeriod] = useState(0);
	const [dateExpire, setDateExpire] = useState(0);
	const [dateCreate, setDateCreate] = useState(0);
	const [tariffEdit, setTariffEdit] = useState(true);
	const [tariff, setTariff] = useState(null);
	const [tariffs, setTariffs] = useState(null);
	const [clients, setClients] = useState(null);
	const [tariffId, setTariffId] = useState(0);
	const [clientId, setClientId] = useState(0);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:domain, setValue:setDomain, bind:bindDomain} = useInput('');
	const {value:databaseHost, setValue:setDatabaseHost, bind:bindDatabaseHost} = useInput('');
	const {value:databaseName, setValue:setDatabaseName, bind:bindDatabaseName} = useInput('');
	const {value:databaseUser, setValue:setDatabaseUser, bind:bindDatabaseUser} = useInput('');
	const {value:databasePassword, setValue:setDatabasePassword, bind:bindDatabasePassword} = useInput('');
	const {value:apiKey, setValue:setApiKey, bind:bindApiKey} = useInput('');
	useEffect(() => {
		(async () => {
		const c = await Clients.getAll();
			setClients(c.data);
			const t = await Tariffs.getAll();
			setTariffs(t.data);
			if (id) {
				const d = await ClientShops.get(id);
				const shop = d.data[0];
				setName(shop.name);
				setDomain(shop.domain);
				setDatabaseHost(shop.databaseHost||'localhost');
				setDatabaseName(shop.databaseName);
				setDatabaseUser(shop.databaseUser);
				setDatabasePassword(shop.databasePassword);
				setApiKey(shop.key);
				setClientName(shop.clientName);
				setClientId(shop.clientId);
				setTariffName(shop.tariffName);
				setTariffId(shop.tariffId);
				setPrice(shop.price);
				setPriceDiscount(shop.priceDiscount);
				setPriceFull(shop.priceFull);
				setPeriod(shop.period);
				setDateExpire(shop.dateExpire);
				setDateCreate(shop.dateCreate);
				setStatus(shop.status);
				setTariffEdit(false);
			}
			setLoading(false);
		})();
	}, [id,setName,setDomain,setDatabaseHost,setDatabaseName,setDatabaseUser,setDatabasePassword,setApiKey,setClientName,setTariffName,setPrice,setPriceDiscount,setPriceFull,setPeriod,setDateExpire,setDateCreate,setTariffs,setClients,setTariff,setStatus,setLoading]);
	const statusHandle = (e) => setStatus(parseInt(e.target.value));
	const tariffHandle = (e) => {
		tariffSelect(parseInt(e.target.value));
		setTariffName(e.target.options[e.target.selectedIndex].text);
	};
	const clientHandle = (e) => {
		setClientId(parseInt(e.target.value));
		setClientName(e.target.options[e.target.selectedIndex].text);
	}
	const periodHandle  = (e) => setPeriod(parseInt(e.target.value));
	const tariffSelect = (tariffId) => {
		setTariffId(tariffId);
		setTariff(tariffs.find(f => f.id === tariffId));
	}
	const save = (e) => {
		e.preventDefault();
		const data = {name,domain,databaseHost:databaseHost||'localhost',databaseName,databaseUser,databasePassword,apiKey,status};
		if (id === 0) {
			data.clientId = clientId;
			data.clientName = clientName;
			data.tariffId = tariffId;
			data.tariffName = tariffName;
			data.price = tariff.price;
			data.priceFull = priceCalc();
			if (tariff.isCloud) {
				data.period = period;
				data.dateExpire = expireCalc();
			}
			ClientShops.add(data, () => alertShow('Операция прошла успешно!'), (err) => alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true));
		} else ClientShops.update(id, data, () => alertShow('Операция прошла успешно!'), (err) => alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true));
	}
	const priceCalc = () => (tariff.isCloud ? period : 1) * tariff.price;
	const expireCalc = () => {
		if (tariff.isCloud) {
			const d = new Date();
			d.setMonth(d.getMonth() + period);
			return dateRound(d.getTime());
		}
		return null;
	}
	const edit = (e) => {
		e.preventDefault();
		setTariffEdit(true);
		tariffSelect(tariffId);
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={id === 0 ? 'Добавить ресторан' : 'Редактирование ресторана'} link='shops'
						controlAdd={<a href="/shop" className="add">Добавить</a>}  />
					<div className="form-container">
						<div className="cell-oneline">
							<div className="cell">
								<label>
									<span>Название</span>
									<input type="text" {...bindName} placeholder="Название" required />
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Домен</span>
									<input type="text" {...bindDomain} placeholder="Домен" required />
								</label>
							</div>
						</div>
						<div><b>База данных</b></div>
						<div className="cell">
							<label>
								<span>Хост</span>
								<input type="text" {...bindDatabaseHost} placeholder="Имя" required />
							</label>
						</div>
						<div className="cell-oneline">
							<div className="cell">
								<label>
									<span>Имя</span>
									<input type="text" {...bindDatabaseName} placeholder="Имя" required />
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Пользователь</span>
									<input type="text" {...bindDatabaseUser} placeholder="Пользователь" required />
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Пароль</span>
									<input type="text" {...bindDatabasePassword} placeholder="Пароль" required />
								</label>
							</div>
						</div>
						<div className="cell">
							<label>
								<span>API Ключ</span>
								<input type="text" {...bindApiKey} placeholder="API Ключ" className="tripple" required />
							</label>
						</div>
						{tariffEdit ?
							<>
								<div className="cell">
									<label>
										<span>Клиент</span>
										<select className="select-minimal" onChange={clientHandle} value={clientId}>
											<option value="0">Выберите пользователя</option>
											{clients.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
										</select>
									</label>
								</div>
								<div className="cell-oneline">
									<div className="cell">
										<label>
											<span>Тариф</span>
											<select className="select-minimal" onChange={tariffHandle} value={tariffId}>
												<option value="0">Выберите тариф</option>
												{tariffs.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
											</select>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Период, мес.</span>
											<select className="select-minimal" onChange={periodHandle} value={period} disabled={tariff && tariff.isCloud === 0}>
												<option value="0">Выберите период</option>
												{[1,2,3,4,5,6,7,8,9,10,11,12,18,24].map((v,i) => <option key={i} value={v.id}>{v}</option>)}
											</select>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Сумма</span>
											<div>{tariff ? `${moneyFormat(priceCalc(), false)} ₽` : '—'}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Работа до</span>
											<div>{tariff && tariff.isCloud ? dateGet(expireCalc()) : '—'}</div>
										</label>
									</div>
								</div>
							</> 
						:
							<>
								<div className="cell-oneline cell-block cell-info">
									<div className="cell">
										<label>
											<span>Тариф</span>
											<div>{tariffName}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Цена</span>
											<div>{moneyFormat(priceDiscount || price, false)} ₽</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Период</span>
											<div>{period} мес.</div>
										</label>
									</div>
									<div className="control">
										<a href="/" onClick={edit}>Сменить тариф</a>
									</div>
								</div>
								<div className="cell-oneline cell-block">
									<div className="cell">
										<label>
											<span>Всего</span>
											<div>{moneyFormat(priceFull, false)} ₽</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Дата завершения</span>
											<div>{dateGet(dateExpire, {showTime:true})}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Дата регистрации</span>
											<div>{dateGet(dateCreate, {showTime:true})}</div>
										</label>
									</div>
								</div>
								<div className="cell">
									<label>
										<span>Клиент</span>
										<div>{clientName}</div>
									</label>
								</div>
							</>
						}
						<div className="cell">
							<label>
								<span>Статус</span>
								<select className="select-minimal" onChange={statusHandle} value={status}>
									{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
								</select>
							</label>
						</div>
						<div className="buttons">
							<button type="submit">Сохранить</button>
						</div>
					</div>
				</form>
			}
			<Alert />
		</Template>
	);
};

export default ShopScreen;