import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';

const get		= async (id)	=> await Http.get('clients', {conditions:[{k:'id',v:id}]});
const getAll	= async ()		=> await Http.get('clients', {orders:[{k:'id',isd:requestOderType.DESC}]});

const getActive	= async ()		=> await Http.get('clients', {conditions:[{k:'status',v:comonStatus.ACTIVE}]});
const getNew	= async ()		=> await Http.get('clients', {conditions:[{k:'status',v:comonStatus.IN_ACTIVE}]});

const update	= (id, data, callback, error) => Http.put('clients', {data,conditions:[{k:'id',v:id}]})
					.then((res) => callback(res))
					.catch((err) => error ? error(err) : {});

export {
	get,
	getAll,
	getActive,
	getNew,
	update
}