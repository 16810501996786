import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Transactions} from './../../Models';

const TransactionsScreen = () => {
	const [transactions, setTransactions] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	useEffect(() => {
		const transactionsGet = async () => {
			const d = await Transactions.getAll();
			setTransactions(dataSet(d.data));
			setDataFull(d.data);
		};
		transactionsGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'transactions', ['orderId','dateCreate','amount','externalId','status']);
	const dataSearch = (data, search) => data.filter(f => f.amount.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 || f.orderId.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 || f.externalId.toLowerCase().indexOf(search.toLowerCase()) !== -1);
	const handleSearch = (e) => {
		const search = e.target.value;
		let d = dataFull;
		if (search.length > 1) d = dataSearch(d, search);
		setTransactions(dataSet(d));
	}
	return (
		<Template>
			{transactions === null ? <Loader /> :
				<DataGrid
					title={'Транзакции'}
					keys={['Заказ','Дата','Сумма','Внешний ID','Статус']}
					data={transactions}
					dataFull={dataFull}
					controlPanel={<>
						<div>
							<input type="search" placeholder="Поиск" onChange={handleSearch} />
						</div>
					</>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default TransactionsScreen;