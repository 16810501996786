import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';

const get		= async (id)	=> await Http.get('clientshops', {conditions:[{k:'id',v:id}]});
const getAll	= async ()		=> await Http.get('clientshops', {orders:[{k:'id',isd:requestOderType.DESC}]});

const getActive	= async ()		=> await Http.get('clientshops', {orders:[{k:'id',isd:requestOderType.DESC},{k:'status',isd:comonStatus.ACTIVE}]});

const select	= async (id)	=> await Http.request('clientshops', 'select', id);

const update	= (id, data, callback, error) => Http.put('clientshops', {data,conditions:[{k:'id',v:id}]})
					.then((res) => callback(res))
					.catch((err) => error ? error(err) : {});

const add		= (data, callback, error) => Http.post('clientshops', {data})
					.then((res) => callback(res))
					.catch((err) => error ? error(err) : {});

export {
	get,
	getAll,
	getActive,
	select,
	update,
	add
}