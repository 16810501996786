const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? '//localhost:8080' : '//nb.kfsamara.ru';

const API = {
	url			: `${url}/api`,
	assets		: `${url}/assets`,
	key			: 'b4d03e33-88d6-41b9-8af3-f5f40627e379',
	version		: '1.1.4'
};

const requestConditionType = {
	EQUAL		: 0,
	NOT_EQUAL	: 1,
	LESS		: 2,
	MORE		: 3,
	IS_NULL		: 4,
	NOT_NULL	: 5,
	LIKE		: 6
};

const requestConcatinationType = {
	AND			: 0,
	OR			: 1
};

const requestOderType = {
	ASC			: 0,
	DESC		: 1
};

const userStatus = {
	ACTIVE		: 0,
	BAN			: 1,
	DELETE		: 2
};
const userStatusName = ['активен','забанен','удален'];

const clientStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1,
	BAN			: 2,
	DELETE		: 3
};
const clientStatusName = ['не активен','активен','забанен','удален'];

const comonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const comonStatusName = ['не активен','активен'];

const clientsShopStatus = {
	SUCCESS		: 0,
	FAIL		: 1
};
const clientsShopStatusName = ['успех','ошибка'];

const clientsShopType = {
	SUCCESS		: 0,
	NOT_FOUND	: 1,
	EXPIRE		: 2,
	WRONG_KEY	: 3,
	IN_ACTIVE	: 4,
	UNKNOWN		: 666
};
const clientsShopTypeName = ['без ошибок','лицензия не найдена','лицензия просрочена','неверный ключ','лицензия не активна','неизветно'];

const transactionStatus = {
	ADD			: 0,
	FINISH		: 1
};
const transactionStatusName = ['создана','завершена'];

const clientStep = {
	ADD			: 0,
	REGISTER	: 1,
	PAY			: 2,
	ADMIN		: 3,
	SITE		: 4,
	APPS		: 5
};
const clientStepName = ['новый','зарегистрирован','доступ оплачен','контрольная панель создана','сайт создан','мобильные приложения созданы'];

const orderStatus = {
	ADDED		: 0,
	PAID		: 1
};
const orderStatusName = ['создан','оплачен'];

export {
	API,
	url,
	requestConditionType,
	requestConcatinationType,
	requestOderType,
	userStatus,
	userStatusName,
	clientStatus,
	clientStatusName,
	comonStatus,
	comonStatusName,
	clientsShopStatus,
	clientsShopStatusName,
	clientsShopType,
	clientsShopTypeName,
	transactionStatus,
	transactionStatusName,
	clientStep,
	clientStepName,
	orderStatus,
	orderStatusName
};