import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import useModal from 'react-hooks-use-modal';

// helpers
import {ls} from '../../Globals/Localstorage';

// logo
import logo from '../../Images/nobug.logo.svg';

// styles
import './styles.css';

const Template = (props) => {
	const [Modal, open, close] = useModal('root', {preventScroll:true});
	const done = () => {
		ls();
		close();
		window.location.href='/login';
	}
	const show = (e) => {
		e.preventDefault();
		open();
	}
	const hide = (e) => {
		e.preventDefault();
		close();
	}
	useEffect(() => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
	}, []);
	return (
		<div className="container">
			<div className="menu">
				<img src={logo} alt="NoBug | Управление общепитом" />
				<ul>
					<li><Link to={'/alerts'}>Сигналы</Link></li>
				</ul>
				<ul>
					<li><b>Диспетчерская</b></li>
					<li><Link to={'/clients'}>Клиенты</Link></li>
					<li><Link to={'/shops'}>Рестораны</Link></li>
					<li><Link to={'/tariffs'}>Тарифы</Link></li>
					<li><Link to={'/pages'}>Страницы</Link></li>
				</ul>
				<ul>
					<li><b>Рестораны</b></li>
					<li><Link to={'/stat'}>Статистика</Link></li>
				</ul>
				<ul>
					<li><b>Контент</b></li>
					<li><Link to={'/tariffs'}>Тарифы</Link></li>
					<li><Link to={'/pages'}>Страницы</Link></li>
				</ul>
				<ul>
					<li><b>Администрирование</b></li>
					<li><Link to={'/orders'}>Заказы</Link></li>
					<li><Link to={'/transactions'}>Транзакции</Link></li>
					<li><Link to={'/settings'}>Настройки</Link></li>
				</ul>
				<div className="logoff">
					<Link to={'/'} onClick={show}>Выход из системы</Link>
				</div>
			</div>
			<div className="main">
				{props.children}
			</div>
			<Modal>
				<div className="confirmcontainer">
					<h4>Подтвердите пожалуйста</h4>
					Вы уверены что хотите выйти из системы администрирования?
					<div className="buttons">
						<button onClick={done}>Да</button>
						<Link to={'/'} className="cancel" onClick={hide}>Отмена</Link>
					</div>
				</div>
			</Modal>
		</div>
	);
};
export default Template;