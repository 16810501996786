import {Link} from 'react-router-dom';

// styles
import './styles.css';

// img
import img from './images/back.svg';

const Back = ({title,link,controlAdd}) => (
	<h4 className="back-title">
		<span>
			<Link to={`/${link}`}><img src={img} alt="назад" /></Link>
			{title}
		</span>
		{controlAdd || null}
	</h4>
);
export default Back;