import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';

// models
import {Tariffs} from './../../Models';

// globals
import {comonStatus,comonStatusName} from '../../Globals/Constants';

// styles
import './styles.css';

const TariffScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const [status, setStatus] = useState(comonStatus.ACTIVE);
	const [isCloud, setIsCloud] = useState(0);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');

	useEffect(() => {
		(async () => {
			if (id) {
				const d = await Tariffs.get(id);
				const tariff = d.data[0];
				setName(tariff.name);
				setPrice(tariff.price);
				setDescription(tariff.description);
				setIsCloud(tariff.isCloud);
				setStatus(tariff.status);
			}
			setLoading(false);
		})();
	}, [id,setName,setPrice,setDescription,setIsCloud,setStatus,setLoading]);
	const statusHandle = (e) => setStatus(parseInt(e.target.value));
	const cloudHandle = (e) => setIsCloud(parseInt(e.target.value));
	const save = (e) => {
		e.preventDefault();
		const data = {name,price,description,isCloud,status};
		if (id === 0) Tariffs.add(data, () => alertShow('Операция прошла успешно!'), (err) => alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true));
		else Tariffs.update(id, data, () => alertShow('Операция прошла успешно!'), (err) => alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true));
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={id === 0 ? 'Добавить тариф' : 'Редактирование тарифа'} link='tariffs'
						controlAdd={<a href="/tariff" className="add">Добавить</a>}  />
					<div className="form-container">
						<div className="cell-oneline">
							<div className="cell">
								<label>
									<span>Название</span>
									<input type="text" {...bindName} placeholder="Название" required />
								</label>
							</div>
						</div>
						<div className="cell">
							<label>
								<span>Цена</span>
								<input type="text" {...bindPrice} placeholder="Цена" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Описание</span>
								<input type="text" {...bindDescription} placeholder="Описание" className="double" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>В облаке</span>
								<select className="select-minimal" onChange={cloudHandle} value={isCloud}>
									<option value="0">нет</option>
									<option value="1">да</option>
								</select>
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Статус</span>
								<select className="select-minimal" onChange={statusHandle} value={status}>
									{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
								</select>
							</label>
						</div>
						<div className="buttons">
							<button type="submit">Сохранить</button>
						</div>
					</div>
				</form>
			}
			<Alert />
		</Template>
	);
};

export default TariffScreen;