import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';

// models
import {Clients,ClientsShopChecks,ClientShops} from './../../Models';

// styles
import './styles.css';

const AlertsScreen = () => {
	const [clients, setClients] = useState(null);
	const [checks, setChecks] = useState(null);
	const [shops, setShops] = useState(null);
	useEffect(() => {
		const dataGet = async () => {
			const c = await Clients.getNew();
			const checks = await ClientsShopChecks.get();
			const s = await ClientShops.getActive();
			setClients(dataSet(c.data));
			setChecks(dataCheckSet(checks.data));
			setShops(s.data);
		};
		dataGet();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'clients', ['id','dateCreate','phone','name']);
	const dataCheckSet = (data) => dgDataPrepare(data, 'checks', ['dateCreate','shopId','domain','type','status'], shops);
	return (
		<Template>
			{clients === null ? <Loader /> :
				<DataGrid
					title={'Новые заявки'} 
					keys={['ID','Дата регистрации','Телефон','Имя']}
					link={'client'}
					data={clients}
					dataFull={clients}
					notFound={<p className="requests-notfound">Новые заявки не найдены</p>} />
			}
			{checks === null ? <Loader /> :
				<DataGrid
					title={'Проверка лицензии'} 
					keys={['Дата проверки','Ресторан','Домен','Тип ответа','Статус']}
					link={'client'}
					data={checks}
					dataFull={checks}
					notFound={<p className="requests-notfound">Проверок не проводилось</p>} />
			}
		</Template>
	);
};

export default AlertsScreen;