import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {ClientShops} from './../../Models';

// helpers
import {empty} from '../../Globals/Utils';

// globals
import {comonStatus,comonStatusName} from '../../Globals/Constants';

const ShopsScreen = () => {
	const [shops, setShops] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	const [status, setStatus] = useState(comonStatus.IN_ACTIVE);
	useEffect(() => {
		const shopsGet = async () => {
			const d = await ClientShops.getAll();
			setShops(dataSet(d.data));
			setDataFull(d.data);
		};
		shopsGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'clients', ['id','clientName','name','domain','tariffName','price','dateExpire','status','dateCreate']);
	const dataSearch = (data, search) => data.filter(f => (f.name && f.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) || (f.phone && f.phone.toLowerCase().indexOf(search.toLowerCase()) !== -1));
	const dataStatus = (data, status) => data.filter(f => f.status === status);
	const handleSearch = (e) => {
		const search = e.target.value;
		let d = dataFull;
		if (search.length > 1) d = dataSearch(d, search);
		if (status !== null) d = dataStatus(d, status);
		setShops(dataSet(d));
	}
	const handleStatus = (e) => {
		let status = e.target.value;
		let d = dataFull;
		if (empty(status)) status = null;
		if (status !== null) d = dataStatus(d, parseInt(status));
		setShops(dataSet(d));
		setStatus(status);
	}
	return (
		<Template>
			{shops === null ? <Loader /> :
				<DataGrid
					title={'Рестораны'} 
					keys={['ID','Клиент','Ресторан','Сайт','Тариф','Цена','Дата завершения','Статус','Дата регистрации']}
					link={'shop'}
					data={shops}
					dataFull={dataFull}
					controlPanel={<>
						<div>
							<input type="search" placeholder="Поиск" onChange={handleSearch} />
						</div>
						<div>
							<span>Статус</span>
							<select onChange={handleStatus}>
								<option value=" ">все</option>
								{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
							</select>
						</div>
					</>}
					controlAdd={<Link to={'/shop'}>Добавить</Link>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default ShopsScreen;