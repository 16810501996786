import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';

// models
import {Pages} from './../../Models';

// styles
import './styles.css';

const PageScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const {value:link, setValue:setLink, bind:bindLink} = useInput('');
	const {value:title, setValue:setTitle, bind:bindTitle} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:keywords, setValue:setKeywords, bind:bindKeywords} = useInput('');
	const {value:content, setValue:setContent, bind:bindContent} = useInput('');
	useEffect(() => {
		(async () => {
			if (id) {
				const d = await Pages.get(id);
				const page = d.data[0];
				setLink(page.link);
				setTitle(page.title);
				setDescription(page.description);
				setKeywords(page.keywords);
				setContent(page.content);
			}
			setLoading(false);
		})();
	}, [id,setLink,setTitle,setDescription,setKeywords,setContent,setLoading]);
	const save = (e) => {
		e.preventDefault();
		const data = {link,title,description,keywords,content};

		if (id === 0) Pages.add(data, () => alertShow('Операция прошла успешно!'), (err) => alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true));
		else Pages.update(id, data, () => alertShow('Операция прошла успешно!'), (err) => alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true));
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={id === 0 ? 'Добавить ресторан' : 'Редактирование ресторана'} link='shops' />
					<div className="form-container">
						<div className="cell">
							<label>
								<span>Ссылка</span>
								<input type="text" {...bindLink} placeholder="Ссылка" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Заголовок</span>
								<input type="text" {...bindTitle} placeholder="Заголовок, название" className="double" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Контент</span>
								<textarea {...bindContent} placeholder="Описание, контент страницы" required></textarea>
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Описание</span>
								<input type="text" {...bindDescription} placeholder="Описание" className="double" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Ключевые слова, теги</span>
								<input type="text" {...bindKeywords} placeholder="Ключевые слова, теги" className="double" required />
							</label>
						</div>
						<div className="buttons">
							<button type="submit">Сохранить</button>
						</div>
					</div>
				</form>
			}
			<Alert />
		</Template>
	);
};

export default PageScreen;