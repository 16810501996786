import React, {useEffect,useState} from 'react';

// plug-ins
import {Line} from 'react-chartjs-2';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Error from '../../Components/Error';

// models
import {ClientShops} from './../../Models';

// helpers
import {dateGet,moneyFormat,tsDay} from '../../Globals/Utils';

// styles
import './styles.css';

const StatisticsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [loadingInner, setLoadingInner] = useState(false);
	const [isError, setIsError] = useState(false);
	const [shops, setShops] = useState(null);
	const [total, setTotal] = useState(null);
	const [count, setCount] = useState(null);
	const [client, setClient] = useState(null);
	const [popular, setPopular] = useState(null);
	const [id, setId] = useState(0);
	const chartOptions = {
		scales:{y:{ticks:{beginAtZero:true,callback:(v) => v % 1 === 0 ? v : null}}},
		maintainAspectRatio:false,
		plugins:{
			legend:{display:false},
			tooltip:{callbacks:{label:(item) => item.dataset.iscount ? `${item.raw} шт.` : (item.dataset.isclient ? `${item.raw} чел.` : `${moneyFormat(item.raw, false)} ₽`)}}
		}
	};
	useEffect(() => {
		const shopsGet = async () => {
			try {
				const d = await ClientShops.getActive();
				if (d.code !== 0) {
					setIsError(true);	
					return;
				}
				setShops(d.data);
				setLoading(false);
			} catch (ex) {
				setIsError(true);
				setLoading(false);
			}
		};
		shopsGet();
	}, []);
	const handleShop = (e) => dataGet(parseInt(e.target.value));
	const dataGet = async (id) => {
		setIsError(false);
		setId(id);
		setLoadingInner(true);
		if (id === 0) return;
		try {
			const d = await ClientShops.select(id);
			if (d.code !== 0) {
				setIsError(true);
				return;
			}
			const data = d.data.orders.reduce((r, a) => {
				const d = Math.floor(a.dateCreate / tsDay);
				r[d] = [...r[d] || [], a];
				return r;
			}, {});
			const prices = [], cnts = [], clientsNew = [], clientsTotal = [];
			const labels = Object.entries(data).map((v) => {
				const d = dateGet(v[0] * tsDay, {hideYear:true});
				prices.push(v[1].reduce((a, v) => a + (v.priceDiscount||v.price), 0));
				cnts.push(v[1].length);
				return `${d.day}.${d.month}`;
			});
			const labelsClients = d.data.clients.slice(-30).map((v) => {
				const d = dateGet(v.date, {hideYear:true});
				clientsNew.push(v.newUsers);
				clientsTotal.push(v.totalUsers);
				return `${d.day}.${d.month}`;
			});
			const total = {labels,datasets:[{data:prices,fill:false,backgroundColor:'rgb(54,162,235)',borderColor:'rgba(54,162,235,.4)',tension:.3}]};
			const count = {labels,datasets:[{data:cnts,fill:false,backgroundColor:'rgb(75,192,192)',borderColor:'rgba(75,192,192,.4)',tension:.3,iscount:true}]};
			const client = {labels:labelsClients,datasets:[{data:clientsNew,fill:false,backgroundColor:'rgb(255,99,132)',borderColor:'rgba(255,99,132,.4)',tension:.3,isclient:true}]};
			setPopular(d.data.popular);
			setTotal(total);
			setCount(count);
			setClient(client);
			setLoadingInner(false);
		} catch (ex) {
			setIsError(true);
			setLoadingInner(false);
		}
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<div className="stat-container">
					<h4>Информация по ресторану</h4>
					<div className="controls">
						<select onChange={handleShop}>
							<option value="0">Выберите ресторан</option>
							{shops.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
						</select>
					</div>
					<div className="stat-info-container">
						{isError ? <Error /> :
							(id === 0 ? <p>Выберите ресторан для просмотра инфорамации</p> :
								loadingInner ? <Loader /> :
								<>
									<div className="block">
										{total.labels.length ?
											<>
												<div className="chart">
													<h4>Выручка за 14 дней, ₽</h4>
													<Line data={total} options={chartOptions} />
												</div>
												<div className="chart">
													<h4>Продажи за 14 дней, шт.</h4>
													<Line data={count} options={chartOptions} />
												</div>
												<div className="chart">
													<h4>Новые пользователи за 30 дней, чел.</h4>
													<Line data={client} options={chartOptions} />
												</div>
											</>
											:
												<div className="empty">
													<h4>Выручка за 14 дней, ₽</h4>
													<p>Нет данных</p>
												</div>
										}
									</div>
									<h4>Популярные позиции за все время</h4>
									<div className="list">
										<ul>
											{popular.map((v,i) => <li key={i}><div className="num">{i+1}</div><div>{v.productName}</div><div className="count">{v.count} шт.</div></li>)}
										</ul>
									</div>
								</>
							)
						}
					</div>
				</div>
			}
		</Template>
	);
};

export default StatisticsScreen;