import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Pages} from './../../Models';

const PagesScreen = () => {
	const [pages, setPages] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	useEffect(() => {
		const pagesGet = async () => {
			const d = await Pages.getAll();
			setPages(dataSet(d.data));
			setDataFull(d.data);
		};
		pagesGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'pages', ['id','title','link']);
	const dataSearch = (data, search) => data.filter(f => (f.name && f.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) || (f.link && f.link.toLowerCase().indexOf(search.toLowerCase()) !== -1));
	const handleSearch = (e) => {
		const search = e.target.value;
		let d = dataFull;
		if (search.length > 1) d = dataSearch(d, search);
		setPages(dataSet(d));
	}
	return (
		<Template>
			{pages === null ? <Loader /> :
				<DataGrid
					title={'Страницы'} 
					keys={['ID','Заголовок','Ссылка']}
					link={'page'}
					data={pages}
					dataFull={dataFull}
					controlPanel={<>
						<div>
							<input type="search" placeholder="Поиск" onChange={handleSearch} />
						</div>
					</>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default PagesScreen;