import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Orders} from './../../Models';

const OrdersScreen = () => {
	const [orders, setOrders] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	useEffect(() => {
		const ordersGet = async () => {
			const d = await Orders.getAll();
			setOrders(dataSet(d.data));
			setDataFull(d.data);
		};
		ordersGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'orders', ['dateCreate','amount','status']);
	const dataSearch = (data, search) => data.filter(f => f.amount.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1);
	const handleSearch = (e) => {
		const search = e.target.value;
		let d = dataFull;
		if (search.length > 1) d = dataSearch(d, search);
		setOrders(dataSet(d));
	}
	return (
		<Template>
			{orders === null ? <Loader /> :
				<DataGrid
					title={'Заказы'}
					tableName={'orders'}
					keys={['Дата','Сумма','Статус']}
					link={'order'}
					data={orders}
					dataFull={dataFull}
					controlPanel={<>
						<div>
							<input type="search" placeholder="Поиск" onChange={handleSearch} />
						</div>
					</>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default OrdersScreen;