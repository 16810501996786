import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start		from './Screens/Start';
import Alerts		from './Screens/Alerts';
import Login		from './Screens/Login';
import Clients		from './Screens/Clients';
import Client		from './Screens/Client';
import Shops		from './Screens/Shops';
import Shop			from './Screens/Shop';
import Tariffs		from './Screens/Tariffs';
import Tariff		from './Screens/Tariff';
import Pages		from './Screens/Pages';
import Page			from './Screens/Page';
import Settings		from './Screens/Settings';
import Setting		from './Screens/Setting';
import Statistics	from './Screens/Statistics';
import Transactions	from './Screens/Transactions';
import Orders		from './Screens/Orders';
import Error		from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/login' component={Login} exact />
			<Route path='/alerts' component={Alerts} exact />
			<Route path='/clients' component={Clients} exact />
			<Route path='/client/:id' component={Client} exact />
			<Route path='/shops' component={Shops} exact />
			<Route path='/shop' component={Shop} exact />
			<Route path='/shop/:id' component={Shop} exact />
			<Route path='/tariffs' component={Tariffs} exact />
			<Route path='/tariff' component={Tariff} exact />
			<Route path='/tariff/:id' component={Tariff} exact />
			<Route path='/pages' component={Pages} exact />
			<Route path='/page/:id' component={Page} exact />
			<Route path='/settings' component={Settings} exact />
			<Route path='/setting' component={Setting} exact />
			<Route path='/setting/:id' component={Setting} exact />
			<Route path='/orders' component={Orders} exact />
			<Route path='/transactions' component={Transactions} exact />
			<Route path='/stat' component={Statistics} exact />
			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;