import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (id)	=> await Http.get('pages', {conditions:[{k:'id',v:id}]});
const getAll	= async ()		=> await Http.get('pages', {orders:[{k:'id',isd:requestOderType.DESC}]});

const update	= (id, data, callback, error) => Http.put('pages', {data,conditions:[{k:'id',v:id}]})
					.then((res) => callback(res))
					.catch((err) => error ? error(err) : {});

const add		= (data, callback, error) => Http.post('pages', {data})
					.then((res) => callback(res))
					.catch((err) => error ? error(err) : {});

export {
	get,
	getAll,
	update,
	add
}