const phoneNormalize = (phone) => {
	if (!empty(phone)) {
		phone = phone.replace(/\s+/gi, '');
		phone = phone.replace(/-/gi, '');
		phone = phone.replace(/\(/gi, '');
		phone = phone.replace(/\)/gi, '');
		phone = phone.replace(/\+/gi, '');
		phone = phone.replace(/[^0-9]+/, '');
		if (!empty(phone)) phone = phone.length < 10 ? '' : phone;
		if (!empty(phone) && phone[0] === '8') phone = phone.substr(1);
		if (!empty(phone)) phone = phone[0] !== '7' ? '7' + phone : phone;
	}
	return phone && phone.length === 11 ? phone : null;
}

const phoneFormatter = (phone) => empty(phone) ? phone : phone.replace('+', '').replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
const empty = (text) => text == null || text === '' || text.toString().trim() === '';

const moneyFormat = (amount, nofraction) => amount ? amount.toFixed(nofraction === undefined || nofraction === true ? 2 : 0).replace(/(\d)(?=(\d{3})+(?!\d)\.?)/g, '$1 ') : 0;

const tsHour = 60 * 60, tsDay = tsHour * 24;

const todayGet = () => {
	const now = new Date();
	return Math.round(new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() / 1000);
}
const isToday = (date) => {
	const today = new Date();
	return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}
const isYestoday = (ts) => {
	const today = todayGet();
	const yestoday = today - tsDay;
	return ts < today && ts >= yestoday;
}
const isTomorrow = (ts) => {
	const today = todayGet();
	const tomorrow = today + (tsDay * 2);
	return ts >= (today + tsDay) && ts < tomorrow;
}
const isWeek = (ts) => {
	const today = todayGet();
	return ts >= (today - (tsDay * 7)) && ts < today;
}
const dateGet = (ts, options) => {
	const d = new Date(typeof ts === 'string' ? ts : ts*1000);
	const months = {
		short:	['янв','фев','мар','апр','май','июн','июл','авг','сен','окт','ноя','дек'],
		full:	['января','февраля','марта','апреля','мая','июня','июля','августа','сентября','октября','ноября','декабря']
	};
	const weekdays = ['понедельник','вторник','среда','четверг','пятница','суббота','воскресенье'];
	options = options || {};
	let m = d.getMonth(), day = d.getDate(), year = d.getFullYear(), separator = '.', yearLetter = '', yearSeparator = '', weekdayname = '';
	if (options.showMonthFullName) {
		m = months.full[m];
		separator = ' ';
	}
	else if (options.showMonthShortName) {
		m = months.short[m];
		separator = ' ';
	}
	else {
		m = m + 1;
		m = m > 9 ? m : `0${m}`;
		day = day > 9 ? day : `0${day}`;
	}
	let time = '';
	if (options.showTime || options.onlyTime) {
		let h = d.getHours(), min = d.getMinutes(), s = d.getSeconds();
		h = h === 0 ? '00' : h;
		min = min > 9 ? min : `0${min}`;
		s = s > 9 ? s : `0${s}`;
		s = options.showSeconds ? `:${s}` : '';
		time = `${h}:${min}${s}`;
		if (options.onlyTime) return time;
		time = `, ${time}`;
	}
	yearSeparator = options.yearSeparator ? `${options.yearSeparator} ` : separator;
	yearLetter = options.yearLetter ? ` ${options.yearLetter} ` : '';
	if (options.yearHide) {
		year = '';
		yearLetter = '';
		yearSeparator = '';
	}
	if (options.neerCheck) {
		let istoday = isToday(d), isyestoday = isYestoday(ts), istomorrow = isTomorrow(ts), isweek = isWeek(ts);
		if (istoday || isyestoday || istomorrow || isweek) {
			m = '';
			day = '';
			separator = '';
			year = '';
			yearLetter = '';
			yearSeparator = '';
			if (isweek) {
				const weekDay = new Date(ts*1000).getDay();
				day = weekdays[weekDay === 0 ? 6 : weekDay - 1];
			}
			if (istoday) day = 'сегодня';
			if (isyestoday) day = 'вчера';
			if (istomorrow) day = 'завтра';
		}
	}
	if (options.showWeekday) {
		const wd = new Date(ts*1000).getDay();
		weekdayname = `${weekdays[wd === 0 ? 6 : wd - 1]}, `;
	}
	if (options.hideYear) return {month:m,day:day};
	if (options.addYearLetter) yearLetter = ' г.';
	if (options.isJson) return {month:m,year:d.getFullYear(),day:day};
	return `${weekdayname}${day}${separator}${m}${yearSeparator}${year}${yearLetter}${time}`.trim();
}

const dateNow = () => dateRound(new Date().getTime());

const timestampGet = (date) => {
	if (empty(date)) return 0;
	let d = date.split('.');
	if (d.length !== 3) return 0;
	return dateRound(new Date(d[2], parseInt(d[1])-1, d[0]).getTime());
}

const timestampConvert = (ts) => {
	const date = new Date(ts * 1000);
	return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
}

const dateSegmentsGet = (date) => {
	date = date || new Date();
	return {
		year:date.getFullYear(),
		month:date.getMonth() + 1,
		day:date.getDate(),
		hour:date.getHours(),
		minutes:date.getMinutes(),
		seconds:date.getSeconds()
	};
}

const zeroPad = (num, places) => String(num).padStart(places, '0');

const dateRound = (ts) => Math.round(ts / 1000);

const rn2br = (text) => text.toString().replace(/\r\n/gi, '<br/>').replace(/\r/gi, '<br/>').replace(/\n/gi, '<br/>');

export {
	tsDay,
	phoneNormalize,
	phoneFormatter,
	empty,
	moneyFormat,
	todayGet,
	dateGet,
	dateNow,
	timestampGet,
	timestampConvert,
	dateSegmentsGet,
	rn2br,
	zeroPad,
	dateRound
}